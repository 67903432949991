import React, { useEffect, useState } from "react";

const NextBid = ({ time, topBidders, price, qty, rprice , bidIncrement}) => {
  const [remainingTime, setremainingTime] = useState(time);
  const [nextBid, setNextBid] = useState(price);
  // console.log(price);
  function getRelevantBid(highestBids, auctionQty) {
    if (highestBids.length === 0) {
      return rprice;
    }
    let totalQuantity = 0;
    for (let i = 0; i < auctionQty; i++) {
      totalQuantity += highestBids[i]?.Quantity;
      if (totalQuantity >= auctionQty) {
        return highestBids[i]?.bidAmount;
      }
    }
    return highestBids[highestBids.length - 1]?.bidAmount;
  }
  const nextEligibleBid=(totalBids, auctionQty) =>{
    let z=leastEligibleBidValue(totalBids, auctionQty);
    // console.log(bidIncrement)
    if(bidIncrement){
      z=z+bidIncrement;
    }
    // console.log(z)
    return z;
  }
  const nextEligibleBidLast=(totalBids, auctionQty) =>{
    let z=leastEligibleBidValue(totalBids, auctionQty);
    // console.log(bidIncrement)
    if(bidIncrement){
      z=z+bidIncrement;
    }
    z=Math.floor(z + (z * 10) / 100);
    // console.log(z)
    return z;
  }
  const leastEligibleBidValue = (totalAuctionBids, auctionQty) => {
    const totalBids = totalAuctionBids.filter(
      (bidder) => bidder.Status !== "canceled",
    );
    if (totalBids.length === 0) {
      return rprice;
    }
    let totalQuantity = 0;
    for (let i = 0; i < auctionQty; i++) {
      totalQuantity += totalBids[i]?.Quantity;
      if (totalQuantity >= auctionQty) {
        let k=[];
        for (let j = 0; j <= i; j++) {
          if(totalBids[j-1]?.bidAmount!==totalBids[j]?.bidAmount && j>0){
          k[k.length] = totalBids[j]?.bidAmount;}
          if(j===0){
            k[j] = totalBids[j]?.bidAmount;
          }
        }
        // console.log(k[k.length-1])
        return k[k.length-1];
    // console.log(bidIncrement)

      }
    }
    // return totalBids[totalBids.length - 1]?.bidAmount;
  };
  const calculateNextBid2 = (topBidders, qty, price) => {
    const validBidders = topBidders.filter(
      (bidder) => bidder.Status !== "canceled",
    );
    return nextEligibleBid(topBidders,qty);
    // return getRelevantBid(validBidders, qty);
  };
  const calculateNextBid = (topBidders, qty, price) => {
    const validBidders = topBidders.filter(
      (bidder) => bidder.Status !== "canceled",
    );
    const t = getRelevantBid(validBidders, qty);
    return nextEligibleBidLast(topBidders,qty);
    return Math.floor(t + (t * 10) / 100);
  };
  function getRemainingTime(time) {
    const currentTimeUTC = new Date();
    const targetDate = new Date(time);
    // const istOffset = 5.5 * 60 * 60 * 1000;
    const currentDate = new Date(currentTimeUTC.getTime());
    const remtime = targetDate.getTime() - currentDate.getTime();
    return remtime;
  }
  useEffect(() => {
    
      const currentTimeRemaining = getRemainingTime(time);
      setremainingTime(currentTimeRemaining);
      setNextBid(calculateNextBid2(topBidders, qty, price));
      // if (currentTimeRemaining < 1 * 60 * 60 * 1000) {
      //   setNextBid(calculateNextBid(topBidders, qty, price));
      // } else {
      //   setNextBid(calculateNextBid2(topBidders, qty, price));
      // }
    
  }, [ topBidders]);
  return (
    <div className="mt-4 w-fit rounded-lg border px-3 py-1 text-sm font-normal text-gray-900">
      Next Bid price should be minimum{" "}
      <span className="ml-1 text-lg font-black"> Rs.{Number(nextBid).toLocaleString("en-IN")}/-</span>
    </div>
  );
};

export default NextBid;
