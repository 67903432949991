import axiosInstance from "@/api/axios";
import { toast } from "sonner";

const getApprovedAuctions = async () => {
  try {
    const res = await axiosInstance.get("/auctions/approved");
    return res.data;
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
};
const getNotApprovedAuctions = async () => {
  try {
    const res = await axiosInstance.get("/auctions/not-approved");
    return res.data;
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
};

const getAllAuctions = async () => {
  try {
    const res = await axiosInstance.get("/auctions");
    return res.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
const getLiveAuctions = async (userId, page) => {
  try {
    const res = await axiosInstance.get("/auctions/live", {
      params: { userId, page },
    });

    return res.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
const getClosedAuctions = async (userId, page) => {
  try {
    const res = await axiosInstance.get("/auctions/closed", {
      params: { userId, page },
    });

    return res.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const getAuctionsById = async (id) => {
  try {
    const res = await axiosInstance.get(`/auctions/${id}`);
    return res.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const getAuctionsByUserId = async (userId) => {
  try {
    const res = await axiosInstance.get(`/auctions/user/${userId}`);
    return res.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const changeAuctionStatus = async (auctionId, payload) => {
  try {
    console.log(auctionId, payload);

    const res = await axiosInstance.patch("/auctions/change-status", {
      auctionID: auctionId,
      ...payload, // Spread the payload to send the necessary fields (status, remarks, scrollingNote, commission, emdRequested)
    });

    return res.data;
  } catch (error) {
    console.log(error);
    toast.error("Failed to change auction status");
    throw new Error(error); // Ensure proper error handling
  }
};


const hostAuction = async (auctionId, status, remark) => {
  try {
    //console.log(auctionId, status, remark);
    const res = await axiosInstance.patch("/auctions/host", {
      auctionID: auctionId,
      status,
      remark,
    });

    return res.data;
  } catch (error) {
    console.log(error);
    toast.error("Failed to change auction status");
    throw Error(error);
  }
};

const updateEmdUsers = async (auctionId, emdUsers) => {
  try {
    const res = await axiosInstance.patch("/auctions/updateEmdUsers", {
      auctionId,
      emdUsers,
    });

    return res.data;
  } catch (error) {
    console.log(error);
    toast.error("Failed to update EMD users");
    throw Error(error);
  }
};

const updateAuctionMetrics = async (auctionId, metrics) => {
  try {
    const res = await axiosInstance.patch("/auctions/updateMetrics", {
      auctionId,
      metrics,
    });
    //console.log(res.data);
    return res.data;
  } catch (error) {
    console.log(error);
    toast.error("Failed to update auction metrics");
    throw Error(error);
  }
};

// Users aprrove request

const sendRequest = async (auctionId, userId) => {
  try {
    const res = await axiosInstance.post(`/auctions/sendRequest/${auctionId}`, {
      userID: userId,
    });
    //console.log(res.data);
    return res.data;
  } catch (error) {
    console.log(error);
    toast.error("Failed to send request");
    throw Error(error);
  }
};

const approveRequest = async (auctionId, userId) => {
  try {
    const res = await axiosInstance.post(
      `/auctions/approveRequest/${auctionId}`,
      {
        UserId: userId,
      },
    );
    //console.log(res.data);
    return res.data;
  } catch (error) {
    console.log(error);
    toast.error("Failed to approve request");
    throw Error(error);
  }
};

const declineRequest = async (auctionId, userId) => {
  try {
    const res = await axiosInstance.post(
      `/auctions/declineRequest/${auctionId}`,
      {
        UserId: userId,
      },
    );
    //console.log(res.data);
    return res.data;
  } catch (error) {
    console.log(error);
    toast.error("Failed to decline request");
    throw Error(error);
  }
};

const removeAllAllowedUsers = async (auctionId) => {
  try {
    const res = await axiosInstance.post(
      `/auctions/removeAllAllowedUsers/${auctionId}`,
    );
    //console.log(res.data);
    return res.data;
  } catch (error) {
    console.log(error);
    toast.error("Failed to remove all allowed users");
    throw Error(error);
  }
};

const approveAllRequests = async (auctionId) => {
  try {
    const res = await axiosInstance.post(
      `/auctions/approveAllRequests/${auctionId}`,
    );
    //console.log(res.data);
    return res.data;
  } catch (error) {
    console.log(error);
    toast.error("Failed to approve all requests");
    throw Error(error);
  }
};

const declineAllRequests = async (auctionId) => {
  try {
    const res = await axiosInstance.post(
      `/auctions/declineAllRequests/${auctionId}`,
    );
    //console.log(res.data);
    return res.data;
  } catch (error) {
    console.log(error);
    toast.error("Failed to decline all requests");
    throw Error(error);
  }
};

const removeAllowedUser = async (auctionId, userId) => {
  try {
    //console.log("Auction Id", auctionId);
    //console.log("User Id", userId);
    const res = await axiosInstance.post(
      `/auctions/removeAllowedUser/${auctionId}`,
      {
        UserId: userId,
      },
    );
    //console.log(res.data);
    return res.data;
  } catch (error) {
    console.log(error);
    toast.error("Failed to remove allowed user");
    throw Error(error);
  }
};

const addAllowedUser = async (auctionId, userId) => {
  try {
    // console.log("Auction Id", auctionId);
    // console.log("User Id", userId);
    const res = await axiosInstance.post(
      `/auctions/addAllowedUser/${auctionId}`,
      {
        UserId: userId,
      },
    );
    // console.log(res.data);
    return res.data;
  } catch (error) {
    console.log(error);
    toast.error("Failed to add allowed user");
    throw Error(error);
  }
};
const addCollabRequests = async (auctionId, userId) => {
  try {
    // console.log("Auction Id", auctionId);
    // console.log("User Id", userId);
    const res = await axiosInstance.post(
      `/auctions/addCollabRequests/${auctionId}`,
      {
        UserId: userId,
      },
    );
    // console.log(res.data);
    return res.data;
  } catch (error) {
    console.log(error);
    toast.error("Failed to add allowed user");
    throw Error(error);
  }
};

const fetchAllowedUsers = async (auctionId) => {
  try {
    // console.log("Fetching allowed Users");
    const res = await axiosInstance.get(
      `/auctions/fetchAllowedUsers/${auctionId}`,
    );
    return res.data;
  } catch (error) {
    console.log(error);
    toast.error("Failed to fetch allowed users");
    throw Error(error);
  }
};
const removeCollabUser = async (auctionId, userId, username) => {
  try {
    console.log("Auction Id", auctionId);
    console.log("User Id", userId);
    const res = await axiosInstance.post(
      `/auctions/removeCollabUser/${auctionId}`,
      {
        UserId: userId, userName: username
      },
    );
    toast.error("Collab removed");
    //console.log(res.data);
    return res.data;
  } catch (error) {
    console.log(error);
    toast.error(error.response.data);
    throw Error(error);
  }
};
const addCollabUser = async (auctionId, userId, username) => {
  try {
    console.log("Auction Id", auctionId);
    console.log("User Id", userId);
    const res = await axiosInstance.post(
      `/auctions/addCollabUser/${auctionId}`,
      {
        UserId: userId, userName: username
      },
    );
    toast.success("Collab created successfully");
    // console.log(res.data);
    return res.data;
  } catch (error) {
    console.log(error);
    toast.error("Failed to add allowed user");
    throw Error(error);
  }
};
const fetchCollabUsers = async (auctionId) => {
  try {
    // console.log("Fetching allowed Users");
    const res = await axiosInstance.get(
      `/auctions/fetchCollabUsers/${auctionId}`,
    );
    return res.data;
  } catch (error) {
    console.log(error);
    toast.error("Failed to fetch Collab users");
    throw Error(error);
  }
};

const updateAuctionAdDetails = async (auctionId, auctionAdBanner , customAdUrl) => { 
  try {
    const res = await axiosInstance.patch(`/auctions/updateAuctionAdDetails`, {
      auctionId,
      auctionAdBanner,
      customAdUrl
    });
    return res.data;
  } catch (error) {
    console.log(error);
    toast.error("Failed to update auction ad details");
    throw Error(error);
  }
}

const fetchRequestedUsers = async (auctionId) => {
  try {
    const res = await axiosInstance.get(
      `/auctions/fetchRequestedUsers/${auctionId}`,
    );
    // console.log(res.data);
    return res.data;
  } catch (error) {
    console.log(error);
    toast.error("Failed to fetch requested users");
    throw Error(error);
  }
};

const updateAdminAd = async (AuctionId, image, position, url, userId, userType) => {
  

  // Typecast position to integer and ensure no decimal part
  const integerPosition = Math.floor(Number(position));
  
  try {
    const res = await axiosInstance.patch('/auctions/updateAdminAd', {
      AuctionId,
      image,
      position: integerPosition,
      url,
      userId,
      userType
    });

    if (res.data.success) {
      toast.success(res.data.message);
    } else {
      toast.error(res.data.message);
    }
    
    return res.data;
  } catch (error) {
    console.error('Error updating admin ad:', error);
    toast.error('Failed to update admin ad');
    throw Error(error);
  }
};

const checkAuctionIdExists = async (auctionId) => {
  try {
    // Make an API call to the check-auction-id endpoint
    const res = await axiosInstance.post('/auctions/check-auction-id', {
      auctionId,
    });

    // Extract the necessary data from the response
    const { availability, charges, auctionAd, exists: auctionExists, adminAdSize } = res.data;

    // Return both the existence status and the size of the adminAd array
    return { availability,charges,auctionAd, auctionExists, adminAdSize };
  } catch (error) {
    console.error('Error checking auction ID:', error);
    throw Error(error);
  }
};

const updateThumbnail = async (AuctionId, thumbnailURL) => {
  try {
    const res = await axiosInstance.patch('/auctions/update-thumbnail', {
      AuctionId,
      thumbnailURL,
    });

    if (res.data.success) {
      toast.success(res.data.message);
    } else {
      toast.error(res.data.message);
    }

    return res.data;
  } catch (error) {
    console.error('Error updating thumbnail:', error);
    toast.error('Failed to update thumbnail');
    throw Error(error);
  }
};


export {
  getApprovedAuctions,
  getNotApprovedAuctions,
  getLiveAuctions,
  getAllAuctions,
  getAuctionsById,
  changeAuctionStatus,
  hostAuction,
  getAuctionsByUserId,
  updateEmdUsers,
  updateAuctionMetrics,
  sendRequest,
  approveRequest,
  declineRequest,
  removeAllAllowedUsers,
  approveAllRequests,
  declineAllRequests,
  removeAllowedUser,
  addAllowedUser,
  fetchAllowedUsers,
  fetchRequestedUsers,
  getClosedAuctions,
  updateAuctionAdDetails,
  updateAdminAd,
  checkAuctionIdExists,
  updateThumbnail,
  fetchCollabUsers,
  addCollabUser,
  removeCollabUser
};
